import { Button } from "@chakra-ui/react";
import React from "react";
import { FaGoogle } from "react-icons/fa";

import useOAuth, { OnAuth, OnError } from "../../hooks/useOAuth";

interface GoogleAuthButtonProps {
  label: string;
  onAuth: OnAuth;
  onError: OnError;
}

const GoogleAuthButton: React.FC<GoogleAuthButtonProps> = ({
  label,
  onAuth,
  onError,
}) => {
  const { onClick, isLoading } = useOAuth({
    provider: "google",
    onAuth,
    onError,
  });

  return (
    <Button
      leftIcon={<FaGoogle />}
      width="100%"
      onClick={onClick}
      isLoading={isLoading}
      data-testid={isLoading ? null : "google-auth-button"}
    >
      {label}
    </Button>
  );
};

export default GoogleAuthButton;
