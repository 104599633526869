import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { CenteredCardLayout, useToast } from "../../../components";
import { usePageTracker, useSendGAEvent } from "../../../utils/googleAnalytics";
import { GoogleAuthButton, MsftAuthButton } from "../../components";
import { useCurrentUserQuery } from "../../graphql";
import useUpdateCurrentUser from "../../graphql/hooks/useUpdateCurrentUser";
import useSignOut from "../../hooks/useSignOut";

const SyncCalendarPage: React.FC = () => {
  usePageTracker("sync_calendar");
  const sendGAEvent = useSendGAEvent();
  const params = new URLSearchParams(useLocation().search);
  const from = params.get("from");
  let redirectTo = from ?? "/";
  if (!redirectTo.startsWith("/")) {
    redirectTo = "/";
  }
  const toast = useToast();
  const navigate = useNavigate();
  const { data: currentUserData } = useCurrentUserQuery();
  const currentUser = currentUserData?.currentUser;

  const [updateCurrentUser, { loading: updateCurrentUserLoading }] =
    useUpdateCurrentUser({
      onError: (err) => {
        toast({
          title: "Error",
          description: err.message,
          status: "error",
        });
      },
    });

  const onSignIn = (): void => {
    sendGAEvent("sign_in", "auth");
    navigate(redirectTo);
  };

  const onSignOut = useSignOut({ currentUser });

  return (
    <CenteredCardLayout
      containerProps={{ alignItems: "center" }}
      onSignOut={onSignOut}
    >
      <Flex
        pt={8}
        flexDirection="column"
        alignItems="center"
        width={["100%", "400px"]}
      >
        <Heading as="h1" fontSize="28px" fontWeight="normal" mb={4}>
          Sync Calendar
        </Heading>
        <Text mb={4}>
          Please sync your calendar so that BrightHire is able to record your
          interviews.
        </Text>

        <GoogleAuthButton
          onError={({ message }) => {
            toast({
              title: "Error",
              description: message,
              status: "error",
            });
          }}
          label="Sync Google Calendar"
          onAuth={onSignIn}
        />
        <Box mt={4} width="100%">
          <MsftAuthButton
            label="Sync Microsoft Calendar"
            onError={({ message }) => {
              toast({
                title: "Error",
                description: message,
                status: "error",
              });
            }}
            onAuth={onSignIn}
          />
        </Box>
        {!currentUser?.requireCalendarSync && (
          <Box mt={4} width="100%">
            <Button
              variant="outline"
              isLoading={updateCurrentUserLoading}
              width="100%"
              data-testid="skip"
              onClick={() => {
                sendGAEvent("calendar_sync", "on_boarding", "skip");
                updateCurrentUser({
                  variables: {
                    calendarSyncSkipped: true,
                  },
                });
              }}
            >
              Skip
            </Button>
          </Box>
        )}
      </Flex>
    </CenteredCardLayout>
  );
};

export default SyncCalendarPage;
