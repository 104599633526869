import invariant from "invariant";
import { useEffect, useState } from "react";

import config from "../../config";
import { useAuthPopup } from "../../utils/popup";
import { CurrentUserFragment, useCurrentUserLazyQuery } from "../graphql";

export type AuthError = {
  message: string;
  code?: string | null;
  extra?: Record<string, any> | null;
};
export type Provider = "google" | "msft";
export type OnAuth = (currentUser: CurrentUserFragment) => void;
export type OnError = (error: AuthError) => void;

const useOAuth = ({
  provider,
  onAuth,
  onError,
}: {
  provider: Provider;
  onAuth: OnAuth;
  onError: OnError;
}): { onClick: () => void; isLoading: boolean } => {
  const authPopup = useAuthPopup();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [testOAuthToken, setTestOAuthToken] = useState<string>("");

  const [getCurrentUser] = useCurrentUserLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: ({ currentUser }) => {
      setIsLoading(false);
      invariant(currentUser, "Missing current user");
      onAuth(currentUser);
    },
    onError: () => {
      setIsLoading(false);
      onError({ message: "An error occured while attempting to log you in." });
    },
  });

  useEffect(() => {
    // Get the mocked auth code for the Cypress test user
    const f = async (): Promise<void> => {
      if (config.appEnv === "test") {
        await new Promise((resolve, reject) => {
          (function waitForAuthCode(retries = 0) {
            if (window.localStorage.getItem("testOAuthToken")) {
              resolve(null);
            }
            // retry for 10s
            if (retries > 100) {
              reject(new Error("Timed out while waiting for auth code"));
            }
            setTimeout(waitForAuthCode, 100, retries + 1);
          })();
        });
        const token = window.localStorage.getItem("testOAuthToken") ?? "";
        setTestOAuthToken(token);
      }
    };
    f();
  }, []);

  /**
   * Handle messages posted from the oauth child window
   * @param e The post message event
   */
  const onMessage = (e: MessageEvent): void => {
    if (e.data?.type === `${provider}_oauth_postmessage`) {
      if (!e.data?.errorMessage) {
        // successful login
        getCurrentUser();
        window.removeEventListener("message", onMessage);
      } else {
        setIsLoading(false);
        onError({
          message: e.data.errorMessage,
          code: e.data?.errorCode,
          extra: e.data?.extra,
        });
      }
    }
  };

  /**
   * Action when the user clicks the "Continue with Microsoft" button
   */
  const onClick = (): void => {
    setIsLoading(true);

    let authPath = `/auth/${provider}/init`;
    if (config.appEnv === "test") {
      authPath += `?auth_code=${testOAuthToken}`;
    }

    if (authPopup.isOpen() && authPopup.currentPath === authPath) {
      authPopup.focus();
      return;
    }

    const { errorMessage } = authPopup.open(authPath);
    if (errorMessage) {
      onError({ message: errorMessage });
      return;
    }

    window.addEventListener("message", onMessage);
  };

  return { onClick, isLoading };
};

export default useOAuth;
