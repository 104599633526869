import { Box, Divider, Heading, Link, Text } from "@chakra-ui/react";
import React from "react";

import { RouterLink, useToast } from "../../../components";
import {
  CurrentUserFragment,
  useUpdateOrganizationZoomNotetakerMutation,
  useZoomHealthQuery,
} from "../../graphql";
import SettingsNote from "./shared/SettingsNote";
import SettingsPageContainer from "./shared/SettingsPageContainer";
import ZoomBotRecordingAuth from "./ZoomBotRecordingAuth";
import ZoomNotetakerSettingsForm from "./ZoomNotetakerSettingsForm";

interface ZoomNotetakerSettingsProps {
  currentUser: CurrentUserFragment;
}

const ZoomNotetakerSettings: React.FC<ZoomNotetakerSettingsProps> = ({
  currentUser,
}) => {
  const toast = useToast();

  const [updateZoomNotetakerSettings, { loading, error }] =
    useUpdateOrganizationZoomNotetakerMutation({
      onCompleted: (data) => {
        if (data?.updateOrganizationZoomNotetaker?.organization) {
          toast({
            status: "success",
            title: "Zoom Notetaker Settings",
            description: "Saved!",
          });
        }
      },
      onError: () => {
        toast({
          status: "error",
          title: "Zoom Notetaker Settings",
          description:
            error?.message ?? "There was a problem - please try again",
        });
      },
    });

  const { organization } = currentUser;

  if (!currentUser.userRole?.canManageIntegrationSettings) {
    return (
      <SettingsPageContainer heading="Zoom">
        <SettingsNote>Access denied</SettingsNote>
      </SettingsPageContainer>
    );
  }

  return (
    <SettingsPageContainer heading="Zoom">
      <Heading as="h4" fontSize="md" mb="7">
        Zoom notetaker
      </Heading>
      <ZoomNotetakerSettingsForm
        zoomNotetakerEnabled={organization.zoomNotetakerEnabled}
        sendCancelRecordingChatMessage={
          organization.sendCancelRecordingChatMessage
        }
        zoomNotetakerDisclaimer={organization.zoomNotetakerDisclaimer}
        zoomNotetakerDisclaimerType={organization.zoomNotetakerDisclaimerType}
        defaultDisclaimer={organization.notetakerRecorderDisclaimerDefault}
        showVirtualOnsiteSetting={organization.showVirtualOnsiteSettingZoom}
        virtualOnsiteEnabled={organization.virtualOnsiteEnabledZoom}
        isLoading={loading}
        error={error}
        onSubmit={(formData) => {
          updateZoomNotetakerSettings({ variables: formData });
        }}
      />
      <Box mb="8">
        <ZoomBotRecordingAuth currentUser={currentUser}>
          <Divider my="10" />
          <Heading as="h4" fontSize="md" mb="7">
            BrightHire Notetaker Zoom App installation
          </Heading>
        </ZoomBotRecordingAuth>
      </Box>
      <LocalRecordingSettingHealthCheck
        authorized={currentUser.organization.isZoomBotAuthorized}
      />
    </SettingsPageContainer>
  );
};

const LocalRecordingSettingHealthCheck: React.FC<{ authorized: boolean }> = ({
  authorized,
}) => {
  const { data } = useZoomHealthQuery();

  const badUsers = data?.badUsers;

  return (
    <>
      {authorized && badUsers?.length ? (
        <>
          <Heading as="h4" fontSize="md" mb="7">
            Configuration Issues
          </Heading>
          <Box ml="8">
            <Text mb={4}>
              The following users have an issue which prevents any meetings
              which they are the host of from being recorded. In order to fix
              this issue, follow the steps described
              <Link
                href="https://support.zoom.com/hc/en/article?id=zm_kb&sysparm_article=KB0063640#h_20df6ea9-fc54-4ab1-a469-018b6abe7eb0"
                target="_blank"
              >
                {" here "}
              </Link>
              to enable the Local Recording setting in Zoom.
            </Text>
            <ul>
              {badUsers.map((user) => (
                <li key={user.id}>
                  <RouterLink to={`/settings/users/info/${user.id}`}>
                    {user.email}
                  </RouterLink>
                  &nbsp;- {user.name}
                </li>
              ))}
            </ul>
          </Box>
        </>
      ) : null}
    </>
  );
};

export default ZoomNotetakerSettings;
